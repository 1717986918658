import { useEffect, useState } from 'react';
import Utils from '../utils';
import FieldBox from '../base/utils/fieldbox';
import Linkbutton from '../base/utils/linkbutton';
import { redirect } from 'react-router-dom';
import api from '../api';
import { useNavigate } from "react-router-dom";
import utils from '../utils';
import { HeaderButtons } from './HeaderButtons';
import { SubheaderButtons } from './SubheaderButtons';
import { RowHeader } from './RowHeader';
import { RowFooter } from './RowFooter';
import { Loader } from '../base/utils/loader';
import { RowData } from './RowData';
import { DashboardFilters } from './dashboardfilters';
import { Popup } from './Popup';
import { CountdownText } from '../base/utils/countdowntext';
import ChoiceBoxMulti from '../base/utils/choiceboxmulti';
import { AttendanceData } from './AttendanceData';
import constants from '../constants';
import { AttendanceHeader } from './AttendanceHeader';

export const DashboardType2 = (props) => {
    console.log("props.subsection", props.subsection);
    const [popupConfig, setPopupConfig] = useState({show:false,title:''});
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [entriesperpage, setEntriesPerPage] = useState("20");
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(NaN);
    const [paramsFilter, setParamsFilter] = useState({});
    const [countdownIndex, setCountdownIndex] = useState(0);
    const [shouldAutoRefresh, setShouldAutoRefresh] = useState("no");

    let isChecked = false;

    useEffect(() => {
      if (isChecked) return;
      isChecked = true;
      
      fetchData(currentTab.key);
      
    }, []);

    useEffect(() => {
      let tab = props.subsection.viewTabs ? props.subsection.viewTabs[0] : {};
      setCurrentTab(tab);
      
      handleButtonClick("tab", tab);
    }, [props.subsection]);


    let tabs = props.subsection.viewTabs.length > 0 ? props.subsection.viewTabs.map((tab, index) => {
      return (
          <div 
              className={tab.key === currentTab.key ? "tab active border-box flex_horizontal center_vertical center_horizontal cursor-pointer" : "tab border-box flex_horizontal center_vertical center_horizontal cursor-pointer"} 
              key={index} onClick={(e) => handleButtonClick("tab", tab)}>{tab.title}</div>
      );
    }) : <></>;

    function handleButtonClick(buttonType, data){
        switch(buttonType) {
           case "tab":
              if (data.key === currentTab.key) return;
              if(["services_active", "services_completed", "services_pending"].includes(data.key)){
                setParamsFilter({status : data.key.split("_")[1]});
              }else if(["payments_all", "payments_pending", "invoices"].includes(data.key)){
                switch(data.key){
                  case "payments_all":
                    setParamsFilter({payment_status : "all"});
                    break;
                  case "payments_pending":
                    setParamsFilter({payment_status : "pending"});
                    break;
                  case "invoices":
                    setParamsFilter({payment_status : "paid"});
                    break;
                  default:
                    break;
                }
              }else if(data.key == "sb_attendance"){
                setParamsFilter({month : ((new Date).getMonth()+1)+"", year : (new Date).getFullYear()+""});
              }else{
                setParamsFilter({});
              }
              setCurrentTab(data);
              setIsLoading(true);
              setPage(1);
              setEntriesPerPage("20");
              setData([]);
              console.log("new tab")
              fetchData(data.key);
              break;
        }
    }

    function handleNavigationButtonClick(buttonType){
        var pageN = page;
        if(buttonType == "previous"){
            if(page > 1){
                pageN = page - 1;
                setPage(pageN);
            }
        }else{
            pageN = page + 1;
            setPage(pageN);
        }
        setIsLoading(true);
        fetchData(currentTab.key, true, entriesperpage, pageN);
    }

    function handleEntriesChange(value){
        console.log(value);
        setEntriesPerPage(value);
        setPage(1);
        setIsLoading(true);
        fetchData(currentTab.key, true, value, 1);
    }

    function handleFilterChange(type, value){
      console.log("handleFilterChange", type, value);
        paramsFilter[type] = value;
        setParamsFilter(paramsFilter);
        setPage(1);
        setEntriesPerPage("20");
        setIsLoading(true);
        fetchData(currentTab.key, true, "20", 1);
    }

    function handleCountdownFinished(){
      console.log("Countdown finished");
      if(shouldAutoRefresh){
        const countdownIndexCopy = countdownIndex;
        setCountdownIndex(countdownIndex + 1);
        fetchData(currentTab.key);
      }
      // props.handleQuestionCountdownFinished(questions[currentQuestionIndexCopy+1]);
      // props.updateCurrentQuestion(questions[currentQuestionIndexCopy+1]);
    }

    function handleChoiceBoxMultiClick(type, id, text){
      console.log(type, id, text)
      if (type == "shouldautorefresh") {
        console.log("refreshyes", id)
          setShouldAutoRefresh(id);
      }
      console.log("refreshyes1", shouldAutoRefresh);
    }

    function handleRowClick(data){
        console.log("dataClicking", data);
        switch(currentTab.key){
          case "services_pending":
          //   if(data && data.oem_info && !data.oem_info.auto_assign){
          //     setPopupConfig({
          //       show: true,
          //       title: 'Assign Service Buddy',
          //       data : data
          //     });
          //   }
          // break;
          case "services_active":
          case "services_completed":
            navigate("/dashboard/services/services/" + data.order_id, {state : {data : data}});
            break;
          case "services_b2b_pending":
          case "services_b2b_active":
          case "services_b2b_completed":
            navigate("/dashboard/services_b2b/services_b2b/" + data.order_id, {state : {data : data}});
            break;
          case "customers":
            // navigate("/dashboard/" + props.subsection.key + "/" + currentTab.key + "/" + data.id, {state : {data : data}});
            navigate("/dashboard/customers/customers/" + data.id, {state : {data : data}});
            break;
          case "sb_attendance":
            break;
          case "sb_active":
          case "sb_available":
          case "sb_offline":
          case "managers":
            navigate("/dashboard/users/users/" + data.user_id, {state : {data : data}});
            // navigate("/dashboard/" + props.subsection.key + "/" + currentTab.key + "/" + data.id, {state : {data : data}});
            break;
          case "payments_all":
          case "payments_pending":
            // navigate("/dashboard/finance/payments/" + data.order_id, {state : {data : data}});
            break;
          case "invoices":
            // navigate("/dashboard/" + props.subsection.key + "/" + currentTab.key + "/" + data.id, {state : {data : data}});
            // navigate("/dashboard/finance/payments_all/" + data.order_id, {state : {data : data}});
            break;
          case "fleet_active":
            navigate("/dashboard/fleets/fleets/" + data.id, {state : {data : data}});
            // navigate("/dashboard/" + props.subsection.key + "/" + currentTab.key + "/" + data.id, {state : {data : data}});
            break;
          case "vehicles_all":
          case "vehicles_active":
          case "vehicles_inactive":
          case "vehicles_um":
            navigate("/dashboard/vehicles/vehicles/" + data.id, {state : {data : data}});
            break;
          case "oem_active":
            navigate("/dashboard/oems/oems/" + data.id, {state : {data : data}});
            break;
          case "warehouse_active":
            navigate("/dashboard/warehouses/warehouses/" + data.id, {state : {data : data}});
            break;
        }
        
    }

    return (
      <>
          {
            popupConfig.show && 
            <Popup popupConfig={popupConfig} closeClick={() => setPopupConfig({...popupConfig,show:false})}/>
          }
          <div className="dashboardbaseinner border-box type2 flex_vertical start_vertical center_horizontal">
              <div className="order1 border-box flex_vertical start_vertical center_horizontal">
                  <div className="header border-box flex_horizontal center_vertical space_between_horizontal">
                      <div className="border-box flex_vertical center_vertical start_horizontal">
                          <div className="title weight600">{props.subsection.subsection}</div>
                          <div className="subtitle colorGray500span">{props.content.subtitle}</div>
                      </div>
                      <HeaderButtons {...props}/>
                      <div className='spacer' />
                      <span className="colorGray500span" style={{fontSize : "12px"}}>Auto Refresh</span>
                      <div style={{width : "10px"}} />
                      <ChoiceBoxMulti type="shouldautorefresh" 
                        objects={
                          [{title : "Yes", id : "yes"}, {title : "No", id : "no"}]} 
                        selected={shouldAutoRefresh}
                        onClick={handleChoiceBoxMultiClick} />
                      {shouldAutoRefresh == "yes" ? 
                      <CountdownText
                        countdownIndex={countdownIndex} duration={59 * 1000}
                        handleCountdownFinished={handleCountdownFinished}
                        className="colorGrayE11span" warnings={true} /> : <></>}
                  </div>
                  {props.subsection.viewTabs.length > 0 ? 
                    <div className="tabs border-box flex_horizontal center_vertical start_horizontal">
                        {tabs}
                    </div>
                  : <></>}
                  <div className="filter border-box flex_horizontal center_vertical end_horizontal bgcolorGray100">
                      <DashboardFilters type={currentTab.key} handleFilterChange={handleFilterChange} paramsFilter={paramsFilter} />
                  </div>
                  <div className="subheader border-box flex_horizontal center_vertical space_between_horizontal">
                      <div className="border-box flex_vertical center_vertical start_horizontal">
                          {/* <div className="title">{currentTab.title}{currentTab.key == "sb_attendance" ? " for "+constants.getMonthName((new Date()).getMonth())+", "+(new Date()).getFullYear() : ""}</div> */}
                          <div className="title">{currentTab.title}</div>
                          {/* <div className="subtitle colorGray500span">{currentTab.title}</div> */}
                      </div>
                      <SubheaderButtons {...props} currentTab={currentTab} entriesperpage={entriesperpage} page={page}/>
                  </div>
                  {currentTab.key != "sb_attendance" ? 
                  <RowHeader {...props} currentTab={currentTab}/>
                  : <></>}
                  {/* <RowHeader1 filters={that.props.filters} tag={that.props.tag} dataState={that.props.dataState} dataData={that.props.dataData} onChoiceBoxClick={that.props.onChoiceBoxClick}/> */}
                  {
                      isLoading ? 
                          <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div>
                      : (currentTab.key != "sb_attendance" ? 
                        <RowData {...props} currentTab={currentTab} data={data} handleRowClick={handleRowClick} />
                        : <AttendanceData {...props} paramsFilter={paramsFilter} currentTab={currentTab} data={data} handleRowClick={handleRowClick} />)
                  }
                  {currentTab.key != "sb_attendance" ? 
                  <RowFooter {...props} currentTab={currentTab} 
                      handleNavigationButtonClick={handleNavigationButtonClick}
                      entriesperpage={entriesperpage} handleEntriesChange={handleEntriesChange} />
                      :<></>}
              </div>
          </div>
      </>
    );


    function fetchData(tag, isReload=true, limit = null, pageNo = null){
      var that = this;
      setIsLoading(true);
      // that.setState({skeleton : true});
      var limit = limit ? limit : (entriesperpage ? entriesperpage.toLowerCase() : "20");
      var pageN = pageNo ? pageNo : (page ? page : 1);
      var params_page = {offset : (pageN - 1)*parseInt(limit), limit : limit};
      // var params_filter = filter ? filter : {};
      var params_filter = {};
      if (paramsFilter.date_from){
        params_filter["from_date"] = paramsFilter.date_from;
      }
      if (paramsFilter.date_to){
        params_filter["end_date"] = paramsFilter.date_to;
      }
      if (paramsFilter.oem_id){
        params_filter["oem_id"] = paramsFilter.oem_id;
      }
      if (paramsFilter.fleet_id){
        params_filter["fleet_id"] = paramsFilter.fleet_id;
      }
      if(tag == "sb_attendance"){
        if(paramsFilter.month){
          params_filter["attendance_month"] = paramsFilter.month;
        }
        if(paramsFilter.year){
          params_filter["attendance_year"] = paramsFilter.year;
        }
      }
      var params = {...params_page, ...params_filter};
      console.log("params", params, tag);
      switch(tag){
        
        case "customers":
          // API.getWHLayout(params).then(function (response) {that.props.setData(response.data.data, "wh_layout");}).catch(function (error) {console.log(error);}).finally(function () {});
          api.getCustomers(params).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          // if(isReload){
          //   api.getCustomers().then(function (response) {
          //     console.log(response.data);
          //       Utils.setWarehouses(response.data.data);
          //       that.fetchLocalData(tag, exists);
          //     }).catch(function (error) {}).finally(function () {});
          // }else{
          //   that.fetchLocalData(tag, exists);
          // }
          // API.getVendors1(params).then(function (response) {that.props.setData(response.data.data, "vendors");}).catch(function (error) {console.log(error);}).finally(function () {});
            break;
        case "managers":
          api.getServiceBuddies({...params, user_role : JSON.stringify(["city_head", "state_head"])}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "sb_active":
          api.getServiceBuddies({...params, user_role : JSON.stringify(["service_buddy"])}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "sb_available":
          api.getServiceBuddies({...params, user_role : JSON.stringify(["service_buddy"]), is_online : 1}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "sb_offline":
          api.getServiceBuddies({...params, user_role : JSON.stringify(["service_buddy"]), is_online : 0}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "sb_attendance":
          api.getServiceBuddies({...params, user_role : JSON.stringify(["service_buddy"])}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "oem_active":
          api.getOEMs({...params, is_online : 0}).then(function (response) {console.log("oem_active", response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "models_active":
          if(localStorage.getItem("oem_id")){
            params = {...params, oem_id : localStorage.getItem("oem_id")};
          }
          api.getAllModels({...params}).then(function (response) {console.log("responsedata", response.data.data); setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "fleet_active":
          api.getFleets({...params}).then(function (response) {console.log("fleet_active", response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "warehouse_active":
          api.getWarehouses({...params}).then(function (response) {console.log("warehouse_active", response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "vehicles_all":
          api.getVehicles({...params}).then(function (response) {console.log("fleet_active", response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "vehicles_active":
          api.getVehicles({...params, status_type : "active"}).then(function (response) {console.log("fleet_active", response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "vehicles_inactive":
          api.getVehicles({...params, status_type : "inactive"}).then(function (response) {console.log("fleet_active", response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "vehicles_um":
          api.getVehicles({...params, status_type : "servicing"}).then(function (response) {console.log("fleet_active", response);setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "services_active":
          api.getOrders({...params, status_type : "ongoing", user_type:"super_admin"}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "services_completed":
          api.getOrders({...params, status_type : "completed", user_type:"super_admin"}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "services_pending":
          api.getOrders({...params, status_type : "pending", user_type:"super_admin"}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "services_b2b_active":
          if(constants.getRole() == "fleet_superadmin"){
            params = {...params, fleet_id : constants.getFleetID()};
          }
          api.getOrders({...params, status_type : "ongoing", user_type:"super_admin", order_type : "B2B"}).then(function (response) {setFetchedData(tag, response.data.data, pageNo);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "services_b2b_completed":
          if(constants.getRole() == "fleet_superadmin"){
            params = {...params, fleet_id : constants.getFleetID()};
          }
          api.getOrders({...params, status_type : "completed", user_type:"super_admin", order_type : "B2B"}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "services_b2b_pending":
          if(constants.getRole() == "fleet_superadmin"){
            params = {...params, fleet_id : constants.getFleetID()};
          }
          api.getOrders({...params, status_type : "pending", user_type:"super_admin", order_type : "B2B"}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "payments_all":
          api.getOrders({...params, status_type : "completed", user_type:"super_admin"}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "payments_pending":
          api.getOrders({...params, status_type : "payment", user_type:"super_admin"}).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
        case "invoices":
          api.getOrders(params).then(function (response) {setFetchedData(tag, response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
          break;
      }
    }

    function setFetchedData(tag, data, pageX = null){
      var that = this;
      console.log("tagging", tag, data);
      switch(tag){
        case "actionables":
        case "periodic_tasks":
          setData(data);
            break;
        default:
          console.log("dataXX", data)
          // if(data && data.length !=0){
          //   setData(data ? data : []);  
          // }
          console.log("pageXXX", page);
          if(page != 0 && page == pageX && (!data || data.length == 0)){
            console.log("pageXXX1", page);
            let pageN = page;
            setPage(pageN);
            fetchData(tag, true, entriesperpage, pageN-1);
          }else{
            setData(data ? data : []);
          }
          break;
      }
      setIsLoading(false); 
      // that.setState({skeleton : false});
    }
}
