import { useEffect, useState } from 'react';
import utils from '../../utils';
import { redirect, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./action.css";
import FieldBox from '../../base/utils/fieldbox';
import Utils from '../../utils';
import api from '../../api';
import { Loader } from '../../base/utils/loader';
import constants from '../../constants';

export const EditVehicleB2B = (props) => {
    const [pageStatus, setPageStatus] = useState("initial");
    const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState(props.subsection.viewTabs ? props.subsection.viewTabs[0] : {});
    const [isLoading, setIsLoading] = useState(false);
    // const [fields, setFields] = useState({gender : "male"});
    const [error, setError] = useState({});
    const[errorGlobal, setErrorGlobal] = useState("");

    const [citiesList, setCitiesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [modelsList, setModelsList] = useState([]);

    const fleetIDCheck = constants.getFleetID();
    const fleetID = fleetIDCheck ? fleetIDCheck : "";

    let { currentTab, id } = useParams();

    let isFetched = false;

    const {state} = useLocation();
    const [warehouses, setWarehouses] = useState([]);
    const [fleets, setFleets] = useState([]);
    const [fields, setFields] = useState(state ? 
        {
            brand_name : state.data.brand_name,
            model_name : state.data.model_name,
            registration_number : state.data.registration_number,
            vin : state.data.vin,
            warehouse_id : state.data.warehouse_id,
            fleet_id : state.data.fleet_id,
        }
        : 
        {
            brand_name : "", 
            model_name : "",
            registration_number : "",
            vin : "",
            warehouse_id : "",
            fleet_id : fleetID,
        });
    // const [data, setData] = useState(state.data);

    const errors = {
        brand_name : "Please enter a brand name",
        model_name : "Please enter a model name",
        registration_number : "Please enter a registration number",
        vin : "Please enter a valid vin",
        warehouse_id : "Please enter a warehouse",
        fleet_id : "Please enter a fleet",
    }

    // input_data = {
    //     "updated_at": now,
    //     "joining_date": datetime.datetime.strptime(str(request.data.get('joining_date')), "%d/%m/%Y"),
    //     "gender": str(request.data.get('gender')),
    //     "dob": str(request.data.get('dob')),
    //     "address": {
    //         "country": str(request.data.get('country')),
    //         "state": str(request.data.get('state')),
    //         "address_line1": str(request.data.get('address_line1')),
    //         "address_line2": str(request.data.get('address_line2')),
    //         "city": str(request.data.get('city')),
    //         "postal_code": str(request.data.get('postal_code'))
    //     },
    //     "location": str(request.data.get('city')),
    //     "city_id": int(request.data.get('city_id')),
    //     "employer": {
    //         "company_name": str(request.data.get('company_name')),
    //         "location": str(request.data.get('company_location')),
    //         "employee_id": str(request.data.get('employee_id'))
    //     },
    //     "service_center_id": int(request.data.get('service_center_id'))
    // }

    // console.log("idEditSB", id, currentTab, data, props.actionType);

    useEffect(() => {
        if (!isFetched){
            isFetched = true;
            fetchData();
            api.getFleets({}).then(function (response) {setFleets(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
            if(fleetID && fleetID.length > 0){
                fetchDataWarehousesList(fleetID);
            }
            
            if(fields.stateName && fields.stateName.length > 0){
                fetchDataCitiesList(fields.stateName);
            }
            if(fields.brand_name && fields.brand_name.length > 0){
                fetchDataModelsList(fields.brand_name);
            }
        }
    }, []);

    
    function handleFieldState(key, value) {
        switch (key) {
            case "test":
            case "company_logo":
            case "fleet_image":
            case "license":
            case "photo":
                break;
            case "fleet_id":
                setFields({...fields, [key] : value, warehouse_id : ""});
                validateField(key, value);
                fetchDataWarehousesList(value);
                break;
            case "stateName":
                setFields({...fields, [key] : value, city : ""});
                validateField(key, value);
                fetchDataCitiesList(value);
                break;
            case "brand_name":
                setFields({...fields, [key] : value, model_name : ""});
                validateField(key, value);
                fetchDataModelsList(value);
                break;
            case "email":
                setFields({...fields, [key] : value.trim().toLowerCase()});
                validateField(key, value.trim().toLowerCase());
                break;
            case "registration_number":
                setFields({...fields, [key] : value.trim().toUpperCase()});
                validateField(key, value.trim().toUpperCase());
                break;
            default:
                setFields({...fields, [key] : value});
                validateField(key, value);
                break;
        }
    }

    function validateField(key, value){
        switch (key) {
            case "email":
                if(!Utils.validateEmail(value)) {
                    console.log("erroring1", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "pincode":
                if(!Utils.validatePinCode(value)) {
                    console.log("erroring2", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "phoneNumber":
                if(!Utils.validatePhoneNumber(value)) {
                    console.log("erroring3", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
            case "company_logo":
            case "fleet_image":
            case "license":
            case "photo":
                return true;
                break;
            default:
                if(!value || value.length == 0) {
                    console.log("erroring", key);
                    error[key] = errors[key];setError(error);return false;
                }else{
                    delete error[key];setError(error);return true;
                }
                break;
        }
    }

    function validateAllFields(){
        let isValid = true;
        
        Object.keys(fields).forEach((key, index) => {
            console.log("validate", key);
            if(!validateField(key, fields[key])){
                console.log("invalid", key, fields[key]);
                isValid = false;
            }
        });
        return isValid;
    }


    function handleButtonClick(buttonType, data){
        if(buttonType == "save"){
            setErrorGlobal("");
            console.log("here");
            if (validateAllFields()){
                console.log("here1");
                let request_data = {
                    brand_name : fields.brand_name,
                    model_name : fields.model_name,
                    registration_number : fields.registration_number,
                    vin : fields.vin,
                    warehouse_id : fields.warehouse_id,
                    fleet_id : fields.fleet_id,
                }
                // var fieldData = Object.assign({}, that.state.fieldData);
                // delete fieldData.image_file;
                setIsLoading(true);
                // let files = [fields.company_logo, fields.fleet_image];
                api.saveVehicleB2B(request_data).then(function (response) {console.log(response);setIsLoading(false);navigate("/dashboard/vehiclesb2b")}).catch(function (error) {console.log(error);setIsLoading(false);setErrorGlobal(error.response.data.message);}).finally(function () {});
            }
        }
    }

    return (<>
        {isLoading ? 
            <div className="loader spacer border-box flex_horizontal center_vertical center_horizontal"><Loader /></div> : 
            
        
        
        <div className="action full_width full_height border-box flex_vertical center_horizontal start_vertical">
            <div className="one full_width border-box flex_horizontal center_horizontal center_vertical">
                <div className="left border-box flex_horizontal center_horizontal center_vertical">
                    <div className="border-box flex_horizontal center_vertical center_horizontal shadow-box">
                        {props.actionType == "add" ? "" : props.data.brand_name + " " + props.data.model_name}
                    </div>
                </div>
                <div className="right border-box flex_vertical start_horizontal end_vertical">
                    <div className="title weight600">{props.actionType == "add" ? "Add new Vehicle" : (props.data.brand_name + " " + props.data.model_name)}</div>
                    <div style={{height : "10px"}}/>
                    <div className="subtitle weight300">{props.actionType == "add" ? "" : ""}</div>
                    <div style={{height : "30px"}}/>
                </div>
            </div>
            <div className="two full_width border-box flex_horizontal center_horizontal start_vertical bgcolorGray200">
                <div className="inner full_width border-box flex_horizontal center_horizontal start_vertical">
                    <div className="left border-box flex_vertical start_horizontal start_vertical">
                        <div className="title weight600">Vehicle details</div>
                        <div className="subtitle colorGray500span">Update Vehicle details</div>
                    </div>
                    <div className="right border-box flex_vertical start_horizontal start_vertical">
                        <div className="inner full_width border-box flex_vertical start_horizontal start_vertical bgcolorSix50 shadow-box">
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="brand_name" title="Vehicle brand name" value={fields.brand_name}  mandatory={true}
                                        selectedValue={fields.brand_name} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.brand_name}
                                        choices={[...[{value:"", title:"Select a brand name"}], ...brandsList.map(o => ({value:o.brand_name, title:o.brand_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="model_name" title="Vehicle model name" value={fields.model_name}  mandatory={true}
                                        selectedValue={fields.model_name} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.model_name}
                                        choices={[...[{value:"", title:"Select a model name"}], ...modelsList.map(o => ({value:o.model_name, title:o.model_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                {fleetIDCheck && fleetIDCheck.length > 0 ? <></> :
                                <>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="fleet_id" title="Fleet" value={fields.fleet_id}  mandatory={true}
                                        selectedValue={fields.fleet_id} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.fleet_id}
                                        choices={[...[{value:"", title:"Select a Fleet"}], ...fleets.map(o => ({value:o.id, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div></>
                                }
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="registration_number" title="Registration number" value={fields.registration_number} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.registration_number} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="vin" title="VIN" value={fields.vin} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.vin} />  
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="warehouse_id" title="Warehouse" value={fields.warehouse_id}  mandatory={true}
                                        selectedValue={fields.warehouse_id} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.warehouse_id}
                                        choices={[...[{value:"", title:"Select a warehouse"}], ...warehouses.map(o => ({value:o.id, title:o.warehouse_name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="stateName" title="State" value={fields.stateName}  mandatory={true}
                                        selectedValue={fields.stateName} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.stateName}
                                        choices={[...[{value:"", title:"Select a state"}], ...utils.getStatesIndia().map(o => ({value:o, title:o}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="city" title="City" value={fields.city}  mandatory={true}
                                        selectedValue={fields.city} onSetFieldState={handleFieldState} 
                                        type="select" size="small" errorDisplay="yes" errorText={error.city}
                                        choices={[...[{value:"", title:"Select a city"}], ...citiesList.map(o => ({value:o.name, title:o.name}))]
                                                .map(o => <option value={o.value} key={o.value}>{o.title}</option>)}/>
                                </div>
                                <div style={{width : "20px"}}></div>
                                <div className="fieldinner border-box flex_vertical center_horizontal center_vertical">
                                    <FieldBox objectKey="pincode" title="Pin Code" value={fields.pincode} size="small" mandatory={true}
                                    onSetFieldState={handleFieldState} type="text" errorDisplay="yes" errorText={error.pincode} />  
                                </div>
                            </div>
                            <div className="fieldouter border-box flex_horizontal center_horizontal center_vertical">
                                <span className="error colorThree500span" style={{fontSize : "13px"}}>{errorGlobal}</span>
                                <div className="spacer"></div>
                                <div className="fieldbuttonbox border-box">
                                    <button className="bttn small solid one" onClick={(e) => handleButtonClick("save")}>Save</button>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );

    function fetchData(){
        setIsLoading(true);
        api.getBrands().then(function (response1) {
            setBrandsList(response1.data.data);setIsLoading(false);
        }).catch(function (error1) {console.log(error1);}).finally(function () {});
      }

    function fetchDataWarehousesList(fleet_id){
        if(fleet_id && fleet_id.length > 0){
            // setIsLoading(true);
            api.getWarehouses({fleet_id : fleet_id}).then(function (response) {setWarehouses(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setCitiesList([]);
        }
    }

    function fetchDataCitiesList(stateName){
        if(stateName && stateName.length > 0){
            // setIsLoading(true);
            api.getCities(stateName).then(function (response) {console.log("responsedata", response.data.data); setCitiesList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setCitiesList([]);
        }
    }

    function fetchDataModelsList(brandName){
        if(brandName && brandName.length > 0){
            // setIsLoading(true);
            api.getModels(brandName).then(function (response) {console.log("responsedata", response.data.data); setModelsList(response.data.data);}).catch(function (error) {console.log(error);}).finally(function () {});
        }else{
            setModelsList([]);
        }
    }
}
